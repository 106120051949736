.when-to-use-wrapper {
  margin-top: 4rem;
}

.when-to-use {
  border-radius: 4rem;
  background-color: #FF4525;
  color: white;
  padding: 1rem;
}

.when-to-use .vertical-carrousel {
  padding: 0rem 1rem;
}

.when-to-use .header {
  font-size: 2rem;
  line-height: 2rem;
  font-weight: bold;
  margin: 0px;
  /* padding: 3rem 4rem; */
}

.when-to-use .arrow {
  max-width: 56px;
  cursor: pointer;
}

.when-to-use .arrow-wrapper {
  padding: 1.2rem;
}

.when-to-use .slick-prev {
  top: 0rem;
  left: calc(50% - 10px);
  transform: rotate(90deg);
}

.when-to-use .slick-next {
  bottom: 2rem;
  left: calc(50% - 10px);
  transform: rotate(90deg);
  top: auto;
}

.when-to-use .pn-slider {
  padding-top: 2rem;
  padding-bottom: 4rem;
}

@media (min-width: 768px) {
  .when-to-use-wrapper {
    margin-top: 5rem;
  }

  .when-to-use {
    padding: 4rem 4rem 1rem 4rem;
  }
  
  .when-to-use .vertical-carrousel {
    padding: 0rem 0rem;
  }
  
  .when-to-use .header {
    font-size: 3.5rem;
    line-height: 3.5rem;
  }

  .when-to-use .arrow {
    max-width: 64px;
  }

  .when-to-use .arrow-wrapper {
    padding: 2rem;
  }
}

@media (min-width: 992px) {
  .when-to-use-wrapper {
    margin-top: 8rem;
  }

  .when-to-use {
    padding: 3rem 2rem 1rem 2rem;
  }
  
  .when-to-use .vertical-carrousel {
    padding: 0rem 4rem;
  }
  
  .when-to-use .header {
    font-size: 4rem;
    line-height: 4rem;
  }

  .when-to-use .arrow {
    max-width: 72px;
  }

  .when-to-use .arrow-wrapper {
    padding: 2rem;
  }
}


@media (min-width: 1200px) {
  .when-to-use-wrapper {
    margin-top: 8rem;
  }

  .when-to-use {
    padding: 3rem 3rem 1rem 3rem;
  }
  
  .when-to-use .vertical-carrousel {
    padding: 0rem 4rem;
  }
  
  .when-to-use .header {
    font-size: 4rem;
    line-height: 4rem;
  }

  .when-to-use .arrow {
    max-width: 72px;
  }

  .when-to-use .arrow-wrapper {
    padding: 2rem;
  }
}

@media (min-width: 1400px) {
  .when-to-use {
    padding: 3rem 4rem 1rem 4rem;
  }
}