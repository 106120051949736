
.easy-item {
  margin: .5rem 0rem;
}

.easy-item .steps .number {
  width: 1rem;
  height: 2rem;
  border: 1px solid gray;
  border-radius: 50%;
  margin: 0px;
  font-size: 1.2rem;
  color: gray;
}

.easy-item:hover .steps .number {
  border: 1px solid #FF4525;
  background-color: #FF4525;
  color: white;
}

.easy-item.selected .steps .number {
  border: 1px solid #FF4525;
  background-color: #FF4525;
  color: white;
}

.easy-item .steps .text {
  font-size: 1em;
  font-weight: bold;
  margin: 0px;
  color: gray;
}

.easy-item:hover .steps .text {
  color: #FF4525;
}

.easy-item.selected .steps .text {
  color: #FF4525;
}

@media (min-width: 199px) { 
  .easy-item {
    margin: .3rem 0rem;
  }

  .easy-item .steps .number {
    width: 1.2rem;
    height: 1.2rem;
    font-size: .8rem;
  }

  .easy-item .steps .text {
    font-size: .7rem;
  }
}

@media (min-width: 344px) {
  .easy-item {
    margin: .3rem 0rem;
  }

  .easy-item .steps .number {
    width: 1.5rem;
    height: 1.5rem;
    font-size: .9rem;
  }

  .easy-item .steps .text {
    font-size: .8rem;
  }
}

@media (min-width: 445px) {
  .easy-item .steps .text {
    font-size: .9rem;
  }
}

@media (min-width: 576px) {
  .easy-item {
    margin: .5rem 0rem;
  }

  .easy-item .steps .number {
    width: 2rem;
    height: 2rem;
    border: 1px solid gray;
    border-radius: 50%;
    margin: 0px;
    font-size: 1.2rem;
    color: gray;
  }

  .easy-item.selected .steps .number {
    border: 1px solid #FF4525;
    background-color: #FF4525;
    color: white;
  }

  .easy-item .steps .text {
    font-size: 1rem;
    font-weight: bold;
    margin: 0px;
    color: gray;
  }

  .easy-item.selected .steps .text {
    color: #FF4525;
  }
}

@media (min-width: 768px) {
  .easy-item {
    margin: 1rem 0rem;
  }

  .easy-item .steps .number {
    width: 2.2rem;
    height: 2.2rem;
    font-size: 1.3rem;
  }

  .easy-item .steps .text {
    font-size: 1.35rem;
  }
}

@media (min-width: 992px) {
  .easy-item {
    margin: .5rem 0rem;
  }

  .easy-item .steps .number {
    width: 2.3rem;
    height: 2.3rem;
    font-size: 1.4rem;
  }

  .easy-item .steps .text {
    font-size: 1.3rem;
  }
}

@media (min-width: 1200px) {
  .easy-item {
    margin: 1rem 0rem;
  }

  .easy-item .steps .number {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1.5rem;
  }

  .easy-item .steps .text {
    font-size: 1.5rem;
  }
}

@media (min-width: 1200px) {
  
}

@media (min-width: 1400px) { 

}