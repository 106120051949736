.video-item {
  padding: 1rem;
}

.video-dom {
  width: 100%;
  border-radius: 1rem;
}

.video-item .video-wrapper {
  position: relative;
  max-width: 400px;
}

.video-item .title-wrapper {
  position: absolute;
}

.video-item .preview {
  min-height: 200px;
  background: gray;
  border-radius: 2rem;
  padding: 1rem;
}

.video-item .play-wrapper {
  position: absolute;
  top: 0px; bottom: 0px;
  left: 0px; right: 0px;
}

.video-item .play {
  max-width: 64px;
  position: absolute;
  top: 0px; bottom: 0px;
  left: 0px; right: 0px;
  margin: auto;
}