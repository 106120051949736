.App {
  text-align: center;
}

.fullscreen {
  /* width: 100vw; */
  /* height: 100vh; */
}

@media (min-width: 768px) {
  .fullscreen {
    /* width: 100vw; */
    height: 100vh;
  }
}

/* Helpers */
.img-absolute {
  position: absolute;
}

.no-padd {
  padding: 0;
}

.pn-text-orange {
  color: #FF4525;
}

.pn-text-gray {
  color: #364550;
}

.pn-text-white {
  color: white;
}

.pn-bg-orange {
  background-color: #FF4525;
}

.pn-bg-gray {
  background-color: #364550;
}

/* fonts */
@font-face {
  font-family: 'DMSans';
  font-weight: 400;
  font-style: normal;
  src: url('/public/assets/fonts/DMSans-Regular.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'DMSans';
  font-weight: 100;
  src: url('/public/assets/fonts/DMSans-Light.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'DMSans';
  font-weight: bold;
  src: url('/public/assets/fonts/DMSans-Bold.ttf') format('truetype'); /* Safari, Android, iOS */
}

/* @font-face {
  font-family: 'DMSans';
  font-weight: bold;
  src: url('/public/assets/fonts/DMSans-Bold.woff') format('woff'), /* Modern Browsers 
       url('/public/assets/fonts/DMSans-Bold.ttf')  format('truetype'); /* Safari, Android, iOS 
} */

body {
  /* font-family: 'DMSans';
  font-weight: 100; */
  font-weight: normal;
}